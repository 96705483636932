import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const MenuIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M24,17.184 L24,20.064 L0,20.064 L0,17.184 L24,17.184 Z M24,10.56 L24,13.44 L0,13.44 L0,10.56 L24,10.56 Z M24,3.936 L24,6.816 L0,6.816 L0,3.936 L24,3.936 Z"
    />
  </SvgIcon>
);

export default MenuIcon;
