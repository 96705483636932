import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import type { FC, MutableRefObject } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { validEnum } from '../utils';
import useClickAwayListener from '../hooks/useClickAwayListener';

import s from './Menu.module.scss';

export enum MenuOrigin {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export const TRANSITION_DURATION_MS = 150;

type Props = {
  anchorOrigin: {
    x: MenuOrigin;
    y: MenuOrigin;
  };
  anchorRef: MutableRefObject<HTMLElement> | MutableRefObject<undefined>;
  classNames?: {
    root?: string;
  };
  fixed?: boolean;
  id: string;
  isOpen: boolean;
  onClose: () => void;
  transformMargin?: {
    x: number;
    y: number;
  };
  transformOrigin: {
    x: MenuOrigin;
    y: MenuOrigin;
  };
};

const Menu: FC<Props> = ({
  anchorOrigin = {
    x: MenuOrigin.Center,
    y: MenuOrigin.Center,
  },
  anchorRef,
  children,
  classNames = {
    root: '',
  },
  fixed = false,
  id,
  isOpen = false,
  onClose,
  transformMargin = {
    x: 0,
    y: 0,
  },
  transformOrigin = {
    x: MenuOrigin.Top,
    y: MenuOrigin.Left,
  },
}) => {
  const [_isOpen, _setIsOpen] = useState(isOpen);
  const [isMounted, setIsMounted] = useState(false);
  const [styles, setStyles] = useState(null);
  const menuRef = useRef();
  const router = useRouter();

  useClickAwayListener([menuRef, anchorRef], () => {
    _setIsOpen(false);
  });

  useEffect(() => {
    _setIsOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let timer;

    if (_isOpen) {
      setIsMounted(true);
    } else {
      timer = setTimeout(() => {
        setIsMounted(false);

        if (onClose) {
          onClose();
        }
      }, TRANSITION_DURATION_MS);
    }

    return () => clearTimeout(timer);
  }, [_isOpen]);

  useEffect(() => {
    const callback = () => {
      if (!menuRef.current) return;

      setStyles(calculateStyles());
    };

    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      _setIsOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events]);

  useEffect(() => {
    if (!menuRef.current) return;

    setStyles(calculateStyles());
  }, [menuRef.current]);

  const calculateStyles = () => {
    const rect = anchorRef.current.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const anchorOffsetTop = rect.top + scrollTop;
    const anchorOffsetLeft = rect.left + scrollLeft;
    const anchorOffsetWidth = rect.width;
    const anchorOffsetHeight = rect.height;

    const anchorOriginX = anchorOrigin.x.toLowerCase();
    const anchorOriginY = anchorOrigin.y.toLowerCase();

    const _styles = {
      top: window.scrollY,
      left: window.scrollX,
      transformOriginX: 0,
      transformOriginY: 0,
    };

    if (anchorOriginX === MenuOrigin.Left) {
      _styles.left += anchorOffsetLeft;
    } else if (anchorOriginX === MenuOrigin.Center) {
      _styles.left += anchorOffsetLeft + anchorOffsetWidth / 2;
    } else if (anchorOriginX === MenuOrigin.Right) {
      _styles.left += anchorOffsetLeft + anchorOffsetWidth;
    }

    if (anchorOriginY === MenuOrigin.Top) {
      if (fixed) {
        //_styles.top += anchorOffsetTop;
      } else {
        _styles.top = anchorOffsetTop;
      }
    } else if (anchorOriginY === MenuOrigin.Center) {
      if (fixed) {
        //_styles.top = anchorOffsetTop + anchorOffsetHeight / 2;
      } else {
        _styles.top += anchorOffsetTop + anchorOffsetHeight / 2;
      }
    } else if (anchorOriginY === MenuOrigin.Bottom) {
      if (fixed) {
        //  _styles.top = anchorOffsetTop + anchorOffsetHeight;
      } else {
        _styles.top += anchorOffsetTop + anchorOffsetHeight;
      }
    }

    const { offsetWidth: menuOffsetWidth, offsetHeight: menuOffsetHeight } =
      menuRef.current as HTMLElement;

    const transformOriginX = transformOrigin.x.toLowerCase();
    const transformOriginY = transformOrigin.y.toLowerCase();

    if (transformOriginX === MenuOrigin.Left) {
      _styles.left -= menuOffsetWidth;
      _styles.transformOriginX = 0;
    } else if (transformOriginX === MenuOrigin.Center) {
      _styles.left -= menuOffsetWidth / 2;
      _styles.transformOriginX = menuOffsetWidth / 2;
    } else if (transformOriginX === MenuOrigin.Right) {
      _styles.left -= menuOffsetWidth;
      _styles.transformOriginX = menuOffsetWidth;
    }

    if (transformOriginY === MenuOrigin.Top) {
      _styles.transformOriginY = 0;
    } else if (transformOriginY === MenuOrigin.Center) {
      _styles.top -= menuOffsetHeight / 2;
      _styles.transformOriginY = menuOffsetHeight / 2;
    } else if (transformOriginY === MenuOrigin.Bottom) {
      _styles.top -= menuOffsetHeight;
      _styles.transformOriginY = menuOffsetHeight;
    }

    if (transformMargin.x > 0) {
      _styles.left -= transformMargin.x;
    }

    if (transformMargin.y > 0) {
      _styles.top += transformMargin.y;
    }

    return _styles;
  };

  if (!validEnum(MenuOrigin, anchorOrigin?.x)) {
    anchorOrigin.x = MenuOrigin.Center;
  }

  if (!validEnum(MenuOrigin, anchorOrigin?.y)) {
    anchorOrigin.y = MenuOrigin.Center;
  }

  if (!validEnum(MenuOrigin, transformOrigin?.x)) {
    transformOrigin.x = MenuOrigin.Top;
  }

  if (!validEnum(MenuOrigin, anchorOrigin?.y)) {
    transformOrigin.y = MenuOrigin.Left;
  }

  const content = (
    <CSSTransition
      in={_isOpen}
      timeout={TRANSITION_DURATION_MS}
      classNames={{
        enterActive: s.enterActive,
        enterDone: s.enterDone,
      }}>
      {_isOpen || isMounted ? (
        <div
          role="presentation"
          ref={menuRef}
          id={id}
          className={cn(s.root, classNames.root)}
          style={
            styles && {
              top: styles.top,
              left: styles.left,
              transformOrigin: `${styles.transformOriginX}px ${styles.transformOriginY}px`,
            }
          }>
          <div role="menu">{children}</div>
        </div>
      ) : (
        <></>
      )}
    </CSSTransition>
  );

  if (typeof window !== 'undefined') {
    return createPortal(content, document.body);
  } else {
    return null;
  }
};

export default Menu;
