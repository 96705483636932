import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const UserIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,14.976 C18.752,14.976 22.752,16.864 24,20.64 L24,24 L0,24 L0,20.64 C1.248,16.864 5.248,14.976 12,14.976 Z M12.096,0 C15.3831988,0 18.048,2.66480117 18.048,5.952 C18.048,9.23919883 15.3831988,11.904 12.096,11.904 C8.80880117,11.904 6.144,9.23919883 6.144,5.952 C6.144,2.66480117 8.80880117,0 12.096,0 Z"
    />
  </SvgIcon>
);

export default UserIcon;
