import type { AppProps } from 'next/app';
import React from 'react';
import type { FC } from 'react';

import App from 'components/App';

import 'styles/global.scss';

const MyApp: FC<AppProps> = (props) => <App {...props} />;

export default MyApp;
