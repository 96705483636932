import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { AnyObject } from 'core/utils';
import type { FormErrorObject } from 'core/utils/forms';

export interface ApiResponseObject {
  cancel: () => void;
  data: AnyObject;
  errors: FormErrorObject[];
  loading: boolean;
  request: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
}
/*
  useApi gives us the following for free:
  - loading state
  - error(s) get formatted and returned as FormErrorObject[]
  - request cancellation (for example: on route change)
*/
const useApi = (key: string): ApiResponseObject => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrorObject[]>(null);
  const [source, setSource] = useState(null);

  const request = async (config: AxiosRequestConfig) => {
    const _source = axios.CancelToken.source();
    setSource(_source);
    setLoading(true);

    let response;

    try {
      response = await axios.request({
        ...config,
        cancelToken: _source.token,
      });

      if (response.data) {
        setData(response.data);
      } else {
        setData(null);
      }

      return response;
    } catch (err) {
      if (err.response?.data?.error) {
        setErrors([err.response.data.error]);
      } else if (err.response?.data?.errors) {
        setErrors(err.response.data.errors);
      }

      throw err;
    } finally {
      setLoading(false);
    }
  };

  // cancel is used by some reset() functions in various forms
  const cancel = () => {
    if (source?.cancel) {
      source.cancel();
    }

    setLoading(false);
  };

  useEffect(() => {
    return () => {
      if (typeof source?.cancel === 'function') {
        source.cancel();
      }
    };
  }, [source]);

  return {
    cancel,
    data,
    errors,
    loading,
    request,
  };
};

export default useApi;
