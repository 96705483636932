const fetcher = async (url: string) => {
  const res = await fetch(url);
  const json = await res.json();

  // why was this here??
  //if (!res.ok) {
  //  if (json.error) {
  //    throw new FormError(
  //      json.error.message,
  //      json.error.code,
  //      json.error.fields
  //    );
  //  }
  //}

  return json;
};

export default fetcher;
