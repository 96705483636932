import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const TickCircleIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M16.8718652,7.45687679 C16.3650485,7.0151128 15.5965591,7.06578378 15.152134,7.57026867 L15.152134,7.57026867 L9.92917839,13.4990577 C9.92085375,13.5085073 9.91170747,13.5172 9.90184703,13.5250337 C9.80973965,13.5982089 9.67575163,13.5828614 9.60257642,13.490754 L9.60257642,13.490754 L8.54847471,12.1639311 C8.51499411,12.1217883 8.4786055,12.08204 8.43957553,12.0449774 C7.97751812,11.6062116 7.24725661,11.6250932 6.80849081,12.0871506 C6.29018669,12.6329686 6.27185852,13.4833907 6.76616553,14.0510323 L6.76616553,14.0510323 L9.03742766,16.6592552 C9.06655729,16.6927065 9.09797382,16.7240953 9.13145074,16.7531954 C9.53097298,17.1004838 10.1363826,17.0581399 10.483671,16.6586176 L10.483671,16.6586176 L16.9907169,9.17288009 C17.4312905,8.66604213 17.3781048,7.89813774 16.8718652,7.45687679 Z"
    />
  </SvgIcon>
);

export default TickCircleIcon;
