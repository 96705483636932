import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const FaceIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M8.688,4.56 C7.6,7.376 5.58715759,9.50739312 2.64947277,10.9541794 C2.61115759,11.4157269 2.592,11.7643337 2.592,12 C2.592,17.1958949 6.80410507,21.408 12,21.408 C17.1958949,21.408 21.408,17.1958949 21.408,12 C21.408,11.3563087 21.2694544,10.4316489 20.9923633,9.22602065 C16.2774544,10.3713402 12.176,8.816 8.688,4.56 Z M8.448,11.616 C9.29630938,11.616 9.984,12.3036906 9.984,13.152 C9.984,14.0003094 9.29630938,14.688 8.448,14.688 C7.59969062,14.688 6.912,14.0003094 6.912,13.152 C6.912,12.3036906 7.59969062,11.616 8.448,11.616 Z M15.552,11.616 C16.4003094,11.616 17.088,12.3036906 17.088,13.152 C17.088,14.0003094 16.4003094,14.688 15.552,14.688 C14.7036906,14.688 14.016,14.0003094 14.016,13.152 C14.016,12.3036906 14.7036906,11.616 15.552,11.616 Z"
    />
  </SvgIcon>
);

export default FaceIcon;
