import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import type { ElementType, FC, MouseEvent } from 'react';

import s from './UserDrawerMenuItem.module.scss';

type Props = {
  badge?: number;
  href: string;
  icon?: ElementType;
  onClick?: (event: MouseEvent) => void;
  selected?: boolean;
  title: string;
};

const UserDrawerMenuItem: FC<Props> = ({
  badge,
  href,
  icon,
  onClick,
  selected = false,
  title,
}) => {
  const router = useRouter();

  const _selected = selected || router.asPath === href;
  const Icon = icon;

  return (
    <Link href={href}>
      <a onClick={onClick} className={cn(s.root, _selected && s.selected)}>
        {icon && (
          <div className={s.icon}>
            <Icon className={s.svgIcon} />
            {badge > 0 && <div className={s.badge}>{badge}</div>}
          </div>
        )}
        <div className={s.title}>{title}</div>
      </a>
    </Link>
  );
};

export default UserDrawerMenuItem;
