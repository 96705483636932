import cn from 'classnames';
import React from 'react';
import type { ElementType, FC } from 'react';

import { ColorVariant, validEnum } from '../utils';

import s from './Label.module.scss';

export enum LabelSize {
  Small = 'small',
  Standard = 'standard',
  // Large = 'large'
}

type Props = {
  classNames?: {
    root?: string;
    svgIcon?: string;
  };
  icon?: ElementType;
  size?: LabelSize;
  subtle?: boolean;
  text: string;
  variant?: ColorVariant;
};

const Label: FC<Props> = ({
  classNames = {
    root: '',
    svgIcon: '',
  },
  icon,
  size = LabelSize.Standard,
  subtle = false,
  text,
  variant = ColorVariant.Primary,
}) => {
  if (!validEnum(LabelSize, size)) {
    size = LabelSize.Standard;
  }

  if (!validEnum(ColorVariant, variant)) {
    variant = ColorVariant.Primary;
  }

  const Icon = icon;

  return (
    <div
      className={cn(
        s.root,
        size === LabelSize.Small && s.sizeSmall,
        size === LabelSize.Standard && s.sizeStandard,
        subtle && s.subtle,
        variant === ColorVariant.Primary && s.variantPrimary,
        variant === ColorVariant.Secondary && s.variantSecondary,
        variant === ColorVariant.Success && s.variantSuccess,
        variant === ColorVariant.Danger && s.variantDanger,
        variant === ColorVariant.Warning && s.variantWarning,
        variant === ColorVariant.Info && s.variantInfo,
        variant === ColorVariant.Neutral && s.variantNeutral,
        classNames.root
      )}>
      {icon && (
        <div className={s.icon}>
          <Icon className={cn(s.iconSvgIcon, classNames?.svgIcon)} />
        </div>
      )}
      <div className={s.text}> {text}</div>
    </div>
  );
};

export default Label;
