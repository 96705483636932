import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const CloseCircleIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M17.3500992,6.55536105 C16.8059191,6.01118096 15.9236289,6.01118096 15.3794488,6.55536105 L15.3794488,6.55536105 L11.9526863,9.98122598 L8.52747174,6.55536105 C7.98329165,6.01118096 7.10100141,6.01118096 6.55682132,6.55536105 C6.01264123,7.09954113 6.01264123,7.98183138 6.55682132,8.52601146 L6.55682132,8.52601146 L9.98268625,11.951226 L6.55682132,15.3779885 L6.46116466,15.4838194 C6.01476694,16.0311342 6.04665249,16.8384701 6.55682132,17.348639 L6.55682132,17.348639 L6.66265222,17.4442956 C7.20996694,17.8906933 8.01730291,17.8588078 8.52747174,17.348639 L8.52747174,17.348639 L11.9526863,13.922226 L15.3794488,17.348639 C15.9236289,17.892819 16.8059191,17.892819 17.3500992,17.348639 C17.8942793,16.8044589 17.8942793,15.9221686 17.3500992,15.3779885 L17.3500992,15.3779885 L13.9236863,11.951226 L17.3500992,8.52601146 L17.4457559,8.42018056 C17.8921536,7.87286584 17.8602681,7.06552988 17.3500992,6.55536105 Z"
    />
  </SvgIcon>
);

export default CloseCircleIcon;
