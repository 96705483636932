import { fill as fillCreator } from './Creator';
import type Creator from './Creator';
import type { PgCreator } from './Creator';

/*
NOTE: When adding new Creator Attributes remember to add them to the
validAttributes() function in utils/creators.ts
*/

export enum CreatorAttributeAssSize {
  Small = 'small',
  Average = 'average',
  Large = 'large',
  ExtraLarge = 'extra-large',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeAssType {
  Natural = 'natural',
  Enhanced = 'enhanced',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeBodyType {
  VerySlim = 'very-slim',
  Petite = 'petite',
  Average = 'average',
  Athletic = 'athletic',
  Muscular = 'muscular',
  Curvy = 'curvy',
  BBW = 'bbw',
  SBBW = 'sbbw',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeBreastSize {
  ExtraSmall = 'extra-small',
  Small = 'small',
  Average = 'average',
  Large = 'large',
  ExtraLarge = 'extra-large',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeBreastType {
  Natural = 'natural',
  Enhanced = 'enhanced',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeEthnicity {
  White = 'white',
  Mixed = 'mixed',
  Asian = 'asian',
  Black = 'black',
  Hispanic = 'hispanic',
  Indian = 'indian',
  Arab = 'arab',
  Other = 'other',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeEyeColor {
  Blue = 'blue',
  Green = 'green',
  Brown = 'brown',
  Hazel = 'hazel',
  Grey = 'grey',
  Amber = 'amber',
  Red = 'red',
  Other = 'other',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeGender {
  Female = 'female',
  Male = 'male',
  Transgender = 'transgender',
  Nonbinary = 'nonbinary',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeHairColor {
  Blonde = 'blonde',
  Brown = 'brown',
  Black = 'black',
  RedAuburn = 'red-auburn',
  Ginger = 'ginger',
  GreyWhite = 'grey-white',
  Vibrant = 'vibrant',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeHeight {
  VeryShort = 'very-short',
  Short = 'short',
  Average = 'average',
  Tall = 'tall',
  VeryTall = 'very-tall',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeOrientation {
  Straight = 'straight',
  Gay = 'gay',
  Lesbian = 'lesbian',
  Bisexual = 'bisexual',
  Pansexual = 'pansexual',
  Asexual = 'asexual',
  Other = 'other',
  NotApplicable = 'n-a',
}

export enum CreatorAttributePenisSize {
  Micropenis = 'micropenis',
  Small = 'small',
  Average = 'average',
  Large = 'large',
  ExtraLarge = 'extra-large',
  NotApplicable = 'n-a',
}

export enum CreatorAttributePiercings {
  NoPiercings = 'no-peircings',
  SomePiercings = 'some-piercings',
  HeavilyPierced = 'heavily-pierced',
  NotApplicable = 'n-a',
}

export enum CreatorAttributePubicHair {
  Shaved = 'shaved',
  LandingStrip = 'landing-strip',
  Trimmed = 'trimmed',
  Bush = 'bush',
  VeryHairy = 'very-hairy',
  NotApplicable = 'n-a',
}

export enum CreatorAttributeTattoos {
  NoTattoos = 'no-tattoos',
  SomeTattoos = 'some-tattoos',
  HeavilyTattood = 'heavily-tattood',
  NotApplicable = 'n-a',
}

export default interface CreatorAttributes {
  assSize?: CreatorAttributeAssSize;
  assType?: CreatorAttributeAssType;
  bodyType?: CreatorAttributeBodyType;
  breastSize?: CreatorAttributeBreastSize;
  breastType?: CreatorAttributeBreastType;
  creatorId?: number;
  dateCreated?: string;
  dateUpdated?: string;
  ethnicity?: CreatorAttributeEthnicity;
  eyeColor?: CreatorAttributeEyeColor;
  gender?: CreatorAttributeGender;
  hairColor?: CreatorAttributeHairColor;
  height?: CreatorAttributeHeight;
  id?: number;
  orientation?: CreatorAttributeOrientation;
  penisSize?: CreatorAttributePenisSize;
  piercings?: CreatorAttributePiercings;
  pubicHair?: CreatorAttributePubicHair;
  tattoos?: CreatorAttributeTattoos;

  creator?: Creator;
}

export interface PgCreatorAttributes {
  ass_size?: CreatorAttributeAssSize;
  ass_type?: CreatorAttributeAssType;
  body_type?: CreatorAttributeBodyType;
  breast_size?: CreatorAttributeBreastSize;
  breast_type?: CreatorAttributeBreastType;
  creator_id?: number;
  date_created?: string | Date;
  date_updated?: string | Date;
  ethnicity?: CreatorAttributeEthnicity;
  eye_color?: CreatorAttributeEyeColor;
  gender?: CreatorAttributeGender;
  hair_color?: CreatorAttributeHairColor;
  height?: CreatorAttributeHeight;
  id?: number;
  orientation?: CreatorAttributeOrientation;
  penis_size?: CreatorAttributePenisSize;
  piercings?: CreatorAttributePiercings;
  pubic_hair?: CreatorAttributePubicHair;
  tattoos?: CreatorAttributeTattoos;

  creator?: PgCreator;
}

export const fill = (data: PgCreatorAttributes): CreatorAttributes => {
  const creatorAttributes: CreatorAttributes = {
    assSize: data.ass_size,
    assType: data.ass_type,
    bodyType: data.body_type,
    breastSize: data.breast_size,
    breastType: data.breast_type,
    creatorId: data.creator_id,
    dateCreated: data.date_created
      ? (data.date_created as Date).toISOString()
      : undefined,
    dateUpdated: data.date_updated
      ? (data.date_updated as Date).toISOString()
      : undefined,
    ethnicity: data.ethnicity,
    eyeColor: data.eye_color,
    gender: data.gender,
    hairColor: data.hair_color,
    height: data.height,
    id: data.id,
    orientation: data.orientation,
    penisSize: data.penis_size,
    piercings: data.piercings,
    pubicHair: data.pubic_hair,
    tattoos: data.tattoos,
  };

  if (data.creator) {
    creatorAttributes.creator = fillCreator(data.creator);
  }

  return creatorAttributes;
};

export const assSizeToString = (assSize: CreatorAttributeAssSize): string => {
  switch (assSize) {
    case CreatorAttributeAssSize.Average:
      return 'Average';
    case CreatorAttributeAssSize.ExtraLarge:
      return 'Extra Large';
    case CreatorAttributeAssSize.Large:
      return 'Large';
    case CreatorAttributeAssSize.NotApplicable:
      return 'n-a';
    case CreatorAttributeAssSize.Small:
      return 'Small';
    default:
      return 'Unknown';
  }
};

export const assTypeToString = (assType: CreatorAttributeAssType): string => {
  switch (assType) {
    case CreatorAttributeAssType.Enhanced:
      return 'Enhanced';
    case CreatorAttributeAssType.Natural:
      return 'Natural';
    case CreatorAttributeAssType.NotApplicable:
      return 'n-a';
    default:
      return 'Unknown';
  }
};

export const bodyTypeToString = (
  bodyType: CreatorAttributeBodyType
): string => {
  switch (bodyType) {
    case CreatorAttributeBodyType.Athletic:
      return 'Athletic';
    case CreatorAttributeBodyType.Average:
      return 'Average';
    case CreatorAttributeBodyType.BBW:
      return 'BBW';
    case CreatorAttributeBodyType.Curvy:
      return 'Curvy';
    case CreatorAttributeBodyType.Muscular:
      return 'Muscular';
    case CreatorAttributeBodyType.NotApplicable:
      return 'n-a';
    case CreatorAttributeBodyType.Petite:
      return 'Petite';
    case CreatorAttributeBodyType.SBBW:
      return 'SBBW';
    case CreatorAttributeBodyType.VerySlim:
      return 'Very Slim';
    default:
      return 'Unknown';
  }
};

export const breastSizeToString = (
  breastSize: CreatorAttributeBreastSize
): string => {
  switch (breastSize) {
    case CreatorAttributeBreastSize.Average:
      return 'Average';
    case CreatorAttributeBreastSize.ExtraLarge:
      return 'Extra Large';
    case CreatorAttributeBreastSize.ExtraSmall:
      return 'Extra Small';
    case CreatorAttributeBreastSize.Large:
      return 'Large';
    case CreatorAttributeBreastSize.NotApplicable:
      return 'n-a';
    case CreatorAttributeBreastSize.Small:
      return 'Small';
    default:
      return 'Unknown';
  }
};

export const breastTypeToString = (
  breastType: CreatorAttributeBreastType
): string => {
  switch (breastType) {
    case CreatorAttributeBreastType.Enhanced:
      return 'Enhanced';
    case CreatorAttributeBreastType.Natural:
      return 'Natural';
    case CreatorAttributeBreastType.NotApplicable:
      return 'n-a';
    default:
      return 'Unknown';
  }
};

export const ethnicityToString = (
  ethnicity: CreatorAttributeEthnicity
): string => {
  switch (ethnicity) {
    case CreatorAttributeEthnicity.Arab:
      return 'Arab';
    case CreatorAttributeEthnicity.Asian:
      return 'Asian';
    case CreatorAttributeEthnicity.Black:
      return 'Black';
    case CreatorAttributeEthnicity.Hispanic:
      return 'Hispanic';
    case CreatorAttributeEthnicity.Indian:
      return 'Indian';
    case CreatorAttributeEthnicity.Mixed:
      return 'Mixed';
    case CreatorAttributeEthnicity.NotApplicable:
      return 'n-a';
    case CreatorAttributeEthnicity.Other:
      return 'Other';
    case CreatorAttributeEthnicity.White:
      return 'White';
    default:
      return 'Unknown';
  }
};

export const eyeColorToString = (
  eyeColor: CreatorAttributeEyeColor
): string => {
  switch (eyeColor) {
    case CreatorAttributeEyeColor.Amber:
      return 'Amber';
    case CreatorAttributeEyeColor.Blue:
      return 'Blue';
    case CreatorAttributeEyeColor.Brown:
      return 'Brown';
    case CreatorAttributeEyeColor.Green:
      return 'Green';
    case CreatorAttributeEyeColor.Grey:
      return 'Grey';
    case CreatorAttributeEyeColor.Hazel:
      return 'Hazel';
    case CreatorAttributeEyeColor.Other:
      return 'Other';
    case CreatorAttributeEyeColor.NotApplicable:
      return 'n-a';
    case CreatorAttributeEyeColor.Red:
      return 'Red';
    default:
      return 'Unknown';
  }
};

export const genderToString = (gender: CreatorAttributeGender): string => {
  switch (gender) {
    case CreatorAttributeGender.Female:
      return 'Female';
    case CreatorAttributeGender.Male:
      return 'Male';
    case CreatorAttributeGender.Nonbinary:
      return 'Nonbinary';
    case CreatorAttributeGender.NotApplicable:
      return 'n-a';
    case CreatorAttributeGender.Transgender:
      return 'Transgender';
    default:
      return 'Unknown';
  }
};

export const hairColorToString = (
  hairColor: CreatorAttributeHairColor
): string => {
  switch (hairColor) {
    case CreatorAttributeHairColor.Black:
      return 'Black';
    case CreatorAttributeHairColor.Blonde:
      return 'Blonde';
    case CreatorAttributeHairColor.Brown:
      return 'Brown';
    case CreatorAttributeHairColor.Ginger:
      return 'Ginger';
    case CreatorAttributeHairColor.GreyWhite:
      return 'Grey/White';
    case CreatorAttributeHairColor.NotApplicable:
      return 'n-a';
    case CreatorAttributeHairColor.RedAuburn:
      return 'Red/Auburn';
    case CreatorAttributeHairColor.Vibrant:
      return 'Vibrant (Pink, Green, Silver, etc)';
    default:
      return 'Unknown';
  }
};

export const heightToString = (height: CreatorAttributeHeight): string => {
  switch (height) {
    case CreatorAttributeHeight.Average:
      return 'Average';
    case CreatorAttributeHeight.NotApplicable:
      return 'n-a';
    case CreatorAttributeHeight.Short:
      return 'Short';
    case CreatorAttributeHeight.Tall:
      return 'Tall';
    case CreatorAttributeHeight.VeryShort:
      return 'Very Short';
    case CreatorAttributeHeight.VeryTall:
      return 'Very Tall';
    default:
      return 'Unknown';
  }
};

export const orientationToString = (
  orientation: CreatorAttributeOrientation
): string => {
  switch (orientation) {
    case CreatorAttributeOrientation.Asexual:
      return 'Asexual';
    case CreatorAttributeOrientation.Bisexual:
      return 'Bisexual';
    case CreatorAttributeOrientation.Gay:
      return 'Gay';
    case CreatorAttributeOrientation.Lesbian:
      return 'Lesbian';
    case CreatorAttributeOrientation.NotApplicable:
      return 'n-a';
    case CreatorAttributeOrientation.Other:
      return 'Other';
    case CreatorAttributeOrientation.Pansexual:
      return 'Pansexual';
    case CreatorAttributeOrientation.Straight:
      return 'Straight';
    default:
      return 'Unknown';
  }
};

export const penisSizeToString = (
  penisSize: CreatorAttributePenisSize
): string => {
  switch (penisSize) {
    case CreatorAttributePenisSize.Average:
      return 'Average';
    case CreatorAttributePenisSize.ExtraLarge:
      return 'Extra Large';
    case CreatorAttributePenisSize.Large:
      return 'Large';
    case CreatorAttributePenisSize.Micropenis:
      return 'Micropenis';
    case CreatorAttributePenisSize.NotApplicable:
      return 'n-a';
    case CreatorAttributePenisSize.Small:
      return 'Small';
    default:
      return 'Unknown';
  }
};

export const piercingsToString = (
  piercings: CreatorAttributePiercings
): string => {
  switch (piercings) {
    case CreatorAttributePiercings.HeavilyPierced:
      return 'Heavily Pierced';
    case CreatorAttributePiercings.NoPiercings:
      return 'No Piercings';
    case CreatorAttributePiercings.NotApplicable:
      return 'n-a';
    case CreatorAttributePiercings.SomePiercings:
      return 'Some Piercings';
    default:
      return 'Unknown';
  }
};

export const pubicHairToString = (
  pubicHair: CreatorAttributePubicHair
): string => {
  switch (pubicHair) {
    case CreatorAttributePubicHair.Bush:
      return 'Bush';
    case CreatorAttributePubicHair.LandingStrip:
      return 'Landing Strip';
    case CreatorAttributePubicHair.NotApplicable:
      return 'n-a';
    case CreatorAttributePubicHair.Shaved:
      return 'Shaved';
    case CreatorAttributePubicHair.Trimmed:
      return 'Trimmed';
    case CreatorAttributePubicHair.VeryHairy:
      return 'Very Hairy';
    default:
      return 'Unknown';
  }
};

export const tattoosToString = (tattoos: CreatorAttributeTattoos): string => {
  switch (tattoos) {
    case CreatorAttributeTattoos.HeavilyTattood:
      return 'Heavily Tattood';
    case CreatorAttributeTattoos.NoTattoos:
      return 'No Tattoos';
    case CreatorAttributeTattoos.NotApplicable:
      return 'n-a';
    case CreatorAttributeTattoos.SomeTattoos:
      return 'Some Tattoos';
    default:
      return 'Unknown';
  }
};
