import { fill as fillCreator } from './Creator';
import type Creator from './Creator';
import type { PgCreator } from './Creator';

export enum CreatorFeatureType {
  Subscription = 'subscription',
  Messaging = 'messaging',
  Links = 'links',
  Customs = 'customs',
  ContentStore = 'content_store',
  DickRatings = 'dick_ratings',
  Charity = 'charity',
}

export enum CreatorSubscriptionBillingCycle {
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export default interface CreatorSettings {
  creatorId?: number;
  dateCreated?: string;
  dateUpdated?: string;
  id?: number;
  messagingEnabled?: boolean;
  messagingInitialTributePrice?: number;
  messagingPricePerMessage?: number;
  subscriptionEnabled?: boolean;
  subscriptionBillingCycle?: CreatorSubscriptionBillingCycle;
  subscriptionPrice?: number;
  subscriptionPrivatePostPreviews?: boolean;
  subscriptionPublicPosts?: boolean;
  subscriptionShowCount?: boolean;

  creator?: Creator;
}

export interface PgCreatorSettings {
  creator_id?: number;
  date_created?: string | Date;
  date_updated?: string | Date;
  id?: number;
  messaging_enabled?: boolean;
  messaging_initial_tribute_price?: number;
  messaging_price_per_message?: number;
  subscription_enabled?: boolean;
  subscription_billing_cycle?: CreatorSubscriptionBillingCycle;
  subscription_price?: number;
  subscription_private_post_previews?: boolean;
  subscription_public_posts?: boolean;
  subscription_show_count?: boolean;

  creator?: PgCreator;
}

export const fill = (data: PgCreatorSettings): CreatorSettings => {
  const creatorSettings: CreatorSettings = {
    creatorId: data.creator_id,
    dateCreated: data.date_created
      ? (data.date_created as Date).toISOString()
      : undefined,
    dateUpdated: data.date_updated
      ? (data.date_updated as Date).toISOString()
      : undefined,
    id: data.id,
    messagingEnabled: data.messaging_enabled,
    messagingInitialTributePrice: data.messaging_initial_tribute_price,
    messagingPricePerMessage: data.messaging_price_per_message,
    subscriptionEnabled: data.subscription_enabled,
    subscriptionBillingCycle: data.subscription_billing_cycle,
    subscriptionPrice: data.subscription_price,
    subscriptionPrivatePostPreviews: data.subscription_private_post_previews,
    subscriptionPublicPosts: data.subscription_public_posts,
    subscriptionShowCount: data.subscription_show_count,
  };

  if (data.creator) {
    creatorSettings.creator = fillCreator(data.creator);
  }

  return creatorSettings;
};

export const subscriptionBillingCycleToString = (
  subscriptionBillingCycle: CreatorSubscriptionBillingCycle
): string => {
  switch (subscriptionBillingCycle) {
    case CreatorSubscriptionBillingCycle.Monthly:
      return 'Monthly';
    case CreatorSubscriptionBillingCycle.Weekly:
      return 'Weekly';
    default:
      return 'Unknown';
  }
};
