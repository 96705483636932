import cn from 'classnames';
import Link from 'next/link';
import React from 'react';
import type { ElementType, FC, MouseEvent } from 'react';

import { ColorVariant } from '../utils';

import s from './MenuItem.module.scss';

type Props = {
  href?: string;
  icon?: ElementType;
  onClick?: (event: MouseEvent) => void;
  title: string;
  variant?: ColorVariant;
};

const MenuItem: FC<Props> = ({
  href,
  icon,
  onClick,
  title,
  variant = ColorVariant.Primary,
}) => {
  const Icon = icon;

  let content;

  if (href) {
    content = (
      <Link href={href}>
        <a className={s.link} href={href} onClick={onClick}>
          {icon && (
            <div className={s.icon}>
              <Icon className={s.svgIcon} />
            </div>
          )}
          <div className={s.title}>{title}</div>
        </a>
      </Link>
    );
  } else {
    content = (
      <div className={s.link} onClick={onClick}>
        {icon && (
          <div className={s.icon}>
            <Icon className={s.svgIcon} />
          </div>
        )}
        <div className={s.title}>{title}</div>
      </div>
    );
  }

  return (
    <div
      className={cn(s.root, variant === ColorVariant.Danger && s.variantDanger)}
      role="menuitem">
      {content}
    </div>
  );
};

export default MenuItem;
