import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import fetcher from 'core/utils/fetcher';

import type { UserApiResponse } from 'pages/api/account/user';
import { accountUserApiRoute } from 'routes';

const useUser = (options?: SWRConfiguration) => {
  return useSWR<UserApiResponse>(accountUserApiRoute(), fetcher, options);
};

export default useUser;
