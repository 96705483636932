import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const AddCircleIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12.048,2.496 C6.77257607,2.496 2.496,6.77257607 2.496,12.048 C2.496,17.3234239 6.77257607,21.6 12.048,21.6 C17.3234239,21.6 21.6,17.3234239 21.6,12.048 C21.6,6.77257607 17.3234239,2.496 12.048,2.496 Z M13.2,6.048 L13.2,10.848 L17.952,10.848 L17.952,13.152 L13.2,13.152 L13.2,17.952 L10.848,17.952 L10.848,13.152 L6.096,13.152 L6.096,10.848 L10.848,10.848 L10.848,6.048 L13.2,6.048 Z"
    />
  </SvgIcon>
);

export default AddCircleIcon;
