import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const ArrowRightIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <polygon
      fill={color}
      className={className}
      points="7.248 0 19.2 12 7.248 24 4.704 21.456 14.16 12 4.704 2.544"
    />
  </SvgIcon>
);

export default ArrowRightIcon;
