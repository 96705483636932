import { ColorVariant } from '../utils';
import { fill as fillUser } from './User';
import type { PgUser } from './User';
import type User from './User';

export enum AffiliateState {
  Active = 'active',
  Inactive = 'inactive',
  Preparing = 'preparing',
  Suspended = 'suspended',
  Terminated = 'terminated',
  Warning = 'warning',
}

export default interface Affiliate {
  dateCreated?: string;
  dateUpdated?: string;
  id?: number;
  state?: AffiliateState;
  userId?: number;
  uuid?: string;

  user?: User;
}

export interface PgAffiliate {
  date_created?: string | Date;
  date_updated?: string | Date;
  id?: number;
  state?: AffiliateState;
  user_id?: number;
  uuid?: string;

  user?: PgUser;
}

export const fill = (data: PgAffiliate): Affiliate => {
  const affiliate: Affiliate = {
    dateCreated: data.date_created
      ? (data.date_created as Date).toISOString()
      : undefined,
    dateUpdated: data.date_updated
      ? (data.date_updated as Date).toISOString()
      : undefined,
    id: data.id,
    state: data.state,
    userId: data.user_id,
    uuid: data.uuid,
  };

  if (data.user) {
    affiliate.user = fillUser(data.user);
  }

  return affiliate;
};

export const stateToColorVariant = (state: AffiliateState): ColorVariant => {
  switch (state) {
    case AffiliateState.Active:
      return ColorVariant.Success;

    case AffiliateState.Inactive:
      return ColorVariant.Neutral;

    case AffiliateState.Preparing:
      return ColorVariant.Warning;

    case AffiliateState.Suspended:
      return ColorVariant.Danger;

    case AffiliateState.Terminated:
      return ColorVariant.Danger;

    case AffiliateState.Warning:
      return ColorVariant.Warning;

    default:
      return ColorVariant.Neutral;
  }
};

export const stateToString = (state: AffiliateState): string => {
  switch (state) {
    case AffiliateState.Active:
      return 'Active';

    case AffiliateState.Inactive:
      return 'Inactive';

    case AffiliateState.Preparing:
      return 'Preparing';

    case AffiliateState.Suspended:
      return 'Suspended';

    case AffiliateState.Terminated:
      return 'Terminated';

    case AffiliateState.Warning:
      return 'Warning';

    default:
      return 'Unknown';
  }
};
