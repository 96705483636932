import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const ClockCircleIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12.48,5.78625 L10.176,5.78625 L10.176,13.632 L16.3299375,17.218875 L17.587875,15.1752188 L12.48,12.3160313 L12.48,5.78625 Z"
    />
  </SvgIcon>
);

export default ClockCircleIcon;
