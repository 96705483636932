import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const HelpIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,-2.84217094e-14 C19.5,-2.84217094e-14 22,6 22,9 C22,12 22,19.5 22,21 C22,22.5 21.5,24 19,24 C17.3333333,24 15,24 12,24 L12,22 L19.5,22 L19.5,20.5 L15.5,20.5 L15.5,12 L19.5,12 C19.5,11 19.5,10 19.5,9 C19.5,7.5 18,2 12,2 C6,2 4.5,7.5 4.5,9 C4.5,10 4.5,11 4.5,12 L9,12 L9,20.5 C8,20.5 6.66666667,20.5 5,20.5 C2.5,20.5 2,19.5 2,17.5 C2,15.5 2,12 2,9 C2,6 4.5,-2.84217094e-14 12,-2.84217094e-14 Z"
    />
  </SvgIcon>
);

export default HelpIcon;
