export enum ColorVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Neutral = 'neutral',
  Transparent = 'transparent',
}

export interface AnyObject {
  [key: string]: any;
}

export const isNumeric = (val: any): boolean => {
  return !(val instanceof Array) && val - parseFloat(val) + 1 >= 0;
};

export const validEnum = (list: AnyObject, value: string): boolean => {
  return Object.values(list).includes(value);
};

export const stringToBoolean = (str: string): boolean => {
  if (typeof str === 'undefined') {
    return false;
  }

  switch (str.toLowerCase()) {
    case 'false':
    case 'no':
    case '0':
    case '':
      return false;
    default:
      return true;
  }
};
