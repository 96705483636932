import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const AttentionCircleIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M11.9570124,15.2875196 C11.1822831,15.2875196 10.56,15.9098027 10.56,16.6774277 C10.56,17.4450528 11.1822831,18.0673359 11.9499082,18.0673359 L11.9499082,18.0673359 C12.7246375,18.0673359 13.3469205,17.4450528 13.3469205,16.6774277 C13.3469205,15.9098027 12.7246375,15.2875196 11.9570124,15.2875196 L11.9570124,15.2875196 Z M11.9534603,5.856 C11.1838734,5.856 10.56,6.47987341 10.56,7.24946027 L10.56,7.24946027 L10.56,12.404872 C10.56,13.1744589 11.1838734,13.7983323 11.9534603,13.7983323 C12.7230471,13.7983323 13.3469205,13.1744589 13.3469205,12.404872 L13.3469205,12.404872 L13.3469205,7.24946027 C13.3469205,6.47987341 12.7230471,5.856 11.9534603,5.856 Z"
    />
  </SvgIcon>
);

export default AttentionCircleIcon;
