import { useEffect } from 'react';
import type { MutableRefObject } from 'react';

export const useClickAwayListener = (
  refOrRefs: MutableRefObject<HTMLElement>[] | MutableRefObject<HTMLElement>,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClickAway = (event: MouseEvent) => {
      let refsArray;

      if (Array.isArray(refOrRefs)) {
        refsArray = refOrRefs;
      } else {
        refsArray = [refOrRefs];
      }

      // console.log('refsArray', refsArray);

      let shouldCallback = true;

      for (const ref of refsArray) {
        if (ref.current && ref.current.contains(event.target)) {
          shouldCallback = false;
        }

        if (ref.current && ref.current === event.target) {
          shouldCallback = false;
        }

        // console.log('ref.current', ref.current);
        // console.log('event.target', event.target);
        // console.log('contains', ref.current.contains(event.target));
      }

      if (shouldCallback) {
        callback();
      }
    };

    document.addEventListener('click', handleClickAway);

    return () => {
      document.removeEventListener('click', handleClickAway);
    };
  }, [refOrRefs]);
};

export default useClickAwayListener;
