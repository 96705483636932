import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const AttentionStarIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M15.6646262,0.592173615 L17.7208885,4.05088293 L21.6560949,4.9413175 L21.2722879,8.9666543 L24,12 L21.2722879,15.031868 L21.6560949,19.0454044 L17.7208885,19.9502886 L15.6646262,23.4302671 L11.9741308,21.821837 L8.27957922,23.4302671 L6.22265735,19.9502886 L2.28296741,19.0454044 L2.64504126,15.031868 L0,12 L2.64504126,8.9666543 L2.28296741,4.9413175 L6.22265735,4.06611063 L8.27957922,0.592173615 L11.9741308,2.19034186 L15.6646262,0.592173615 Z M13.056,15.264 L10.848,15.264 L10.848,17.472 L13.056,17.472 L13.056,15.264 Z M13.056,6.528 L10.848,6.528 L10.848,13.152 L13.056,13.152 L13.056,6.528 Z"
    />
  </SvgIcon>
);

export default AttentionStarIcon;
