import type Creator from './Creator';
import type { PgCreator } from './Creator';
import { fill as fillCreator } from './Creator';

export enum CreatorApplicantIssueType {
  // Basic Info
  // - Username
  BasicInfoUsernameMissing = 'basic-info-username-missing',
  BasicInfoUsernameRacism = 'basic-info-username-racism',
  BasicInfoUsernameHateSpeech = 'basic-info-username-hate-speech',
  BasicInfoUsernameIllegalActivity = 'basic-info-username-illegal-activity',
  BasicInfoUsernameStaffImpersonation = 'basic-info-username-staff-impersonation',
  BasicInfoUsernameCreatorImpersonation = 'basic-info-username-creator-impersonation',
  // - Name
  BasicInfoNameMissing = 'basic-info-name-missing',
  BasicInfoNameRacism = 'basic-info-name-racism',
  BasicInfoNameHateSpeech = 'basic-info-name-hate-speech',
  BasicInfoNameIllegalActivity = 'basic-info-name-illegal-activity',
  BasicInfoNameStaffImpersonation = 'basic-info-name-staff-impersonation',
  BasicInfoNameCreatorImpersonation = 'basic-info-name-creator-impersonation',
  BasicInfoNameIncludesCode = 'basic-info-name-includes-code',
  // - Profile Picture
  BasicInfoProfilePictureMissing = 'basic-info-profile-picture-missing',
  BasicInfoProfilePictureExplicitNudity = 'basic-info-profile-picture-explicit-nudity',
  BasicInfoProfilePictureRacism = 'basic-info-profile-picture-racism',
  BasicInfoProfilePictureHateSpeech = 'basic-info-profile-picture-hate-speech',
  BasicInfoProfilePictureIllegalActivity = 'basic-info-profile-picture-illegal-activity',
  BasicInfoProfilePictureStaffImpersonation = 'basic-info-profile-picture-staff-impersonation',
  BasicInfoProfilePictureCreatorImpersonation = 'basic-info-profile-picture-creator-impersonation',
  // - Cover Photo
  BasicInfoCoverPhotoMissing = 'basic-info-cover-photo-missing',
  BasicInfoCoverPhotoExplicitNudity = 'basic-info-cover-photo-explicit-nudity',
  BasicInfoCoverPhotoRacism = 'basic-info-cover-photo-racism',
  BasicInfoCoverPhotoHateSpeech = 'basic-info-cover-photo-hate-speech',
  BasicInfoCoverPhotoIllegalActivity = 'basic-info-cover-photo-illegal-activity',
  BasicInfoCoverPhotoStaffImpersonation = 'basic-info-cover-photo-staff-impersonation',
  BasicInfoCoverPhotoCreatorImpersonation = 'basic-info-cover-photo-creator-impersonation',
  // - Status
  BasicInfoStatusRacism = 'basic-info-status-racism',
  BasicInfoStatusHateSpeech = 'basic-info-status-hate-speech',
  BasicInfoStatusIllegalActivity = 'basic-info-status-illegal-activity',
  BasicInfoStatusStaffImpersonation = 'basic-info-status-staff-impersonation',
  BasicInfoStatusCreatorImpersonation = 'basic-info-status-creator-impersonation',
  BasicInfoStatusIncludesCode = 'basic-info-status-includes-code',
  // - About
  BasicInfoAboutRacism = 'basic-info-about-racism',
  BasicInfoAboutHateSpeech = 'basic-info-about-hate-speech',
  BasicInfoAboutIllegalActivity = 'basic-info-about-illegal-activity',
  BasicInfoAboutStaffImpersonation = 'basic-info-about-staff-impersonation',
  BasicInfoAboutCreatorImpersonation = 'basic-info-about-creator-impersonation',
  BasicInfoAboutIncludesCode = 'basic-info-about-includes-code',

  // Twitter Verification
  // - Username
  TwitterVerificationUsernameNotFound = 'twitter-verification-username-not-found',
  TwitterVerificationUsernamePrivate = 'twitter-verification-username-private',
  TwitterVerificationUsernameNotCreator = 'twitter-verification-username-not-creator',
  // - Tweet
  TwitterVerificationTweetNotFound = 'twitter-verification-tweet-not-found',
  TwitterVerificationTweetMissingCode = 'twitter-verification-tweet-missing-code',
  TwitterVerificationTweetIncorrectCode = 'twitter-verification-tweet-incorrect-code',

  /*
  // FUTURE
  //
  // Basic Identity Verification
  // - Personal Info
  IdentityVerificationPersonalInfoFirstNameUnacceptable = 'identity-verification-personal-info-first-name-unacceptable',
  IdentityVerificationPersonalInfoLastNameUnacceptable = 'identity-verification-personal-info-last-name-unacceptable',
  IdentityVerificationPersonalInfoDobUnderage = 'identity-verification-personal-info-dob-underage', // should never happen as we catch this in our form validation
  // - Platform Links
  IdentityVerificationPlatformLinksAdultPlatformLinkUnacceptable = 'identity-verification-platform-links-adult-platform-link-unacceptable',
  IdentityVerificationPlatformLinksAdultPlatformLinkInactive = 'identity-verification-platform-links-adult-platform-link-inactive',
  IdentityVerificationPlatformLinksAdultPlatformLinkMismatch = 'identity-verification-platform-links-adult-platform-link-mismatch',
  IdentityVerificationPlatformLinksSocialMediaLinkUnacceptable = 'identity-verification-platform-links-social-media-link-unacceptable',
  IdentityVerificationPlatformLinksSocialMediaLinkInactive = 'identity-verification-platform-links-social-media-link-inactive',
  IdentityVerificationPlatformLinksSocialMediaLinkMismatch = 'identity-verification-platform-links-social-media-link-mismatch',
  // - Selfie Photo
  IdentityVerificationSelfiePhotoSignMissing = 'identity-verification-selfie-photo-sign-missing', // Selfie is not holding up handwritten note
  IdentityVerificationSelfiePhotoSignDateMissing = 'identity-verification-selfie-photo-sign-date-missing', // Selfie handwritten sign is missing their Date
  IdentityVerificationSelfiePhotoSignDateIncorrect = 'identity-verification-selfie-photo-sign-date-incorrect', // Selfie handwritten sign has wrong date (roughly)
  IdentityVerificationSelfiePhotoSignForPlatformMissing = 'identity-verification-selfie-photo-sign-platform-missing', // Selfie handwritten sign is missing "For SWer"
  IdentityVerificationSelfiePhotoSignForPlatformIncorrect = 'identity-verification-selfie-photo-sign-platform-incorrect', // Selfie handwritten sign has wrong platform
  IdentityVerificationSelfiePhotoNotClear = 'identity-verification-selfie-photo-not-clear', // Selfie photo is not clear, blurry
  IdentityVerificationSelfiePhotoOutOfFrame = 'identity-verification-selfie-photo-out-of-frame', // Selfie photo is out of frame
  IdentityVerificationSelfiePhotoNotColor = 'identity-verification-selfie-photo-not-color', // Selfie photo is not in color
  IdentityVerificationSelfiePhotoTextUnreadable = 'identity-verification-selfie-photo-text-unreadable', // Selfie photo ID text is unreadable
  */
}

export default interface CreatorApplicantIssue {
  active?: boolean;
  creatorId?: number;
  dateCreated?: string;
  dateUpdated?: string;
  id?: number;
  type?: CreatorApplicantIssueType;
  uuid?: string;

  creator?: Creator;
}

export interface PgCreatorApplicantIssue {
  active?: boolean;
  creator_id?: number;
  date_created?: string | Date;
  date_updated?: string | Date;
  id?: number;
  type?: CreatorApplicantIssueType;
  uuid?: string;

  creator?: PgCreator;
}

export const fill = (data: PgCreatorApplicantIssue): CreatorApplicantIssue => {
  const creatorApplicantIssue: CreatorApplicantIssue = {
    active: data.active,
    creatorId: data.creator_id,
    dateCreated: data.date_created
      ? (data.date_created as Date).toISOString()
      : undefined,
    dateUpdated: data.date_updated
      ? (data.date_updated as Date).toISOString()
      : undefined,
    id: data.id,
    type: data.type,
    uuid: data.uuid,
  };

  if (data.creator) {
    creatorApplicantIssue.creator = fillCreator(data.creator);
  }

  return creatorApplicantIssue;
};

export const BASIC_INFO_USERNAME_ISSUE_TYPES = [
  CreatorApplicantIssueType.BasicInfoUsernameMissing,
  CreatorApplicantIssueType.BasicInfoUsernameRacism,
  CreatorApplicantIssueType.BasicInfoUsernameHateSpeech,
  CreatorApplicantIssueType.BasicInfoUsernameIllegalActivity,
  CreatorApplicantIssueType.BasicInfoUsernameStaffImpersonation,
  CreatorApplicantIssueType.BasicInfoUsernameCreatorImpersonation,
  CreatorApplicantIssueType.BasicInfoNameIncludesCode,
];

export const BASIC_INFO_NAME_ISSUE_TYPES = [
  CreatorApplicantIssueType.BasicInfoNameMissing,
  CreatorApplicantIssueType.BasicInfoNameRacism,
  CreatorApplicantIssueType.BasicInfoNameHateSpeech,
  CreatorApplicantIssueType.BasicInfoNameIllegalActivity,
  CreatorApplicantIssueType.BasicInfoNameStaffImpersonation,
  CreatorApplicantIssueType.BasicInfoNameCreatorImpersonation,
];

export const BASIC_INFO_PROFILE_PICTURE_ISSUE_TYPES = [
  CreatorApplicantIssueType.BasicInfoProfilePictureMissing,
  CreatorApplicantIssueType.BasicInfoProfilePictureExplicitNudity,
  CreatorApplicantIssueType.BasicInfoProfilePictureRacism,
  CreatorApplicantIssueType.BasicInfoProfilePictureHateSpeech,
  CreatorApplicantIssueType.BasicInfoProfilePictureIllegalActivity,
  CreatorApplicantIssueType.BasicInfoProfilePictureStaffImpersonation,
  CreatorApplicantIssueType.BasicInfoProfilePictureCreatorImpersonation,
];

export const BASIC_INFO_COVER_PHOTO_ISSUE_TYPES = [
  CreatorApplicantIssueType.BasicInfoCoverPhotoMissing,
  CreatorApplicantIssueType.BasicInfoCoverPhotoExplicitNudity,
  CreatorApplicantIssueType.BasicInfoCoverPhotoRacism,
  CreatorApplicantIssueType.BasicInfoCoverPhotoHateSpeech,
  CreatorApplicantIssueType.BasicInfoCoverPhotoIllegalActivity,
  CreatorApplicantIssueType.BasicInfoCoverPhotoStaffImpersonation,
  CreatorApplicantIssueType.BasicInfoCoverPhotoCreatorImpersonation,
];

export const BASIC_INFO_STATUS_ISSUE_TYPES = [
  CreatorApplicantIssueType.BasicInfoStatusRacism,
  CreatorApplicantIssueType.BasicInfoStatusHateSpeech,
  CreatorApplicantIssueType.BasicInfoStatusIllegalActivity,
  CreatorApplicantIssueType.BasicInfoStatusStaffImpersonation,
  CreatorApplicantIssueType.BasicInfoStatusCreatorImpersonation,
  CreatorApplicantIssueType.BasicInfoStatusIncludesCode,
];

export const BASIC_INFO_ABOUT_ISSUE_TYPES = [
  CreatorApplicantIssueType.BasicInfoAboutRacism,
  CreatorApplicantIssueType.BasicInfoAboutHateSpeech,
  CreatorApplicantIssueType.BasicInfoAboutIllegalActivity,
  CreatorApplicantIssueType.BasicInfoAboutStaffImpersonation,
  CreatorApplicantIssueType.BasicInfoAboutCreatorImpersonation,
  CreatorApplicantIssueType.BasicInfoAboutIncludesCode,
];

export const BASIC_INFO_ISSUE_TYPES = [
  ...BASIC_INFO_USERNAME_ISSUE_TYPES,
  ...BASIC_INFO_NAME_ISSUE_TYPES,
  ...BASIC_INFO_PROFILE_PICTURE_ISSUE_TYPES,
  ...BASIC_INFO_COVER_PHOTO_ISSUE_TYPES,
  ...BASIC_INFO_STATUS_ISSUE_TYPES,
  ...BASIC_INFO_ABOUT_ISSUE_TYPES,
];

export const TWITTER_VERIFICATION_USERNAME_ISSUE_TYPES = [
  CreatorApplicantIssueType.TwitterVerificationUsernameNotFound,
  CreatorApplicantIssueType.TwitterVerificationUsernamePrivate,
  CreatorApplicantIssueType.TwitterVerificationUsernameNotCreator,
];

export const TWITTER_VERIFICATION_TWEET_ISSUE_TYPES = [
  CreatorApplicantIssueType.TwitterVerificationTweetNotFound,
  CreatorApplicantIssueType.TwitterVerificationTweetMissingCode,
  CreatorApplicantIssueType.TwitterVerificationTweetIncorrectCode,
];

export const TWITTER_VERIFICATION_ISSUE_TYPES = [
  ...TWITTER_VERIFICATION_USERNAME_ISSUE_TYPES,
  ...TWITTER_VERIFICATION_TWEET_ISSUE_TYPES,
];

export const RESOLVABLE_ISSUES_WITHOUT_CHANGE = [
  CreatorApplicantIssueType.TwitterVerificationUsernamePrivate,
  CreatorApplicantIssueType.TwitterVerificationTweetNotFound,
  CreatorApplicantIssueType.TwitterVerificationTweetMissingCode,
  CreatorApplicantIssueType.TwitterVerificationTweetIncorrectCode,
];

export const typeToTitle = (type: CreatorApplicantIssueType): string => {
  switch (type) {
    // Basic Info
    // - Username
    case CreatorApplicantIssueType.BasicInfoUsernameMissing:
      return 'Username - Missing';

    case CreatorApplicantIssueType.BasicInfoUsernameRacism:
      return 'Username - Racism';

    case CreatorApplicantIssueType.BasicInfoUsernameHateSpeech:
      return 'Username - Hate Speech';

    case CreatorApplicantIssueType.BasicInfoUsernameIllegalActivity:
      return 'Username - Illegal Activity';

    case CreatorApplicantIssueType.BasicInfoUsernameStaffImpersonation:
      return 'Username - Staff Impersonation';

    case CreatorApplicantIssueType.BasicInfoUsernameCreatorImpersonation:
      return 'Username - Creator Impersonation';

    // - Name
    case CreatorApplicantIssueType.BasicInfoNameMissing:
      return 'Name - Missing';

    case CreatorApplicantIssueType.BasicInfoNameRacism:
      return 'Name - Racism';

    case CreatorApplicantIssueType.BasicInfoNameHateSpeech:
      return 'Name - Hate Speech';

    case CreatorApplicantIssueType.BasicInfoNameIllegalActivity:
      return 'Name - Illegal Activity';

    case CreatorApplicantIssueType.BasicInfoNameStaffImpersonation:
      return 'Name - Staff Impersonation';

    case CreatorApplicantIssueType.BasicInfoNameCreatorImpersonation:
      return 'Name - Creator Impersonation';

    case CreatorApplicantIssueType.BasicInfoNameIncludesCode:
      return 'Name - Includes Code';

    // - Profile Picture
    case CreatorApplicantIssueType.BasicInfoProfilePictureMissing:
      return 'Profile Picture - Missing';

    case CreatorApplicantIssueType.BasicInfoProfilePictureExplicitNudity:
      return 'Profile Picture - Explicit Nudity';

    case CreatorApplicantIssueType.BasicInfoProfilePictureRacism:
      return 'Profile Picture - Racism';

    case CreatorApplicantIssueType.BasicInfoProfilePictureHateSpeech:
      return 'Profile Picture - Hate Speech';

    case CreatorApplicantIssueType.BasicInfoProfilePictureIllegalActivity:
      return 'Profile Picture - Illegal Activity';

    case CreatorApplicantIssueType.BasicInfoProfilePictureStaffImpersonation:
      return 'Profile Picture - Staff Impersonation';

    case CreatorApplicantIssueType.BasicInfoProfilePictureCreatorImpersonation:
      return 'Profile Picture - Creator Impersonation';

    // - Cover Photo
    case CreatorApplicantIssueType.BasicInfoCoverPhotoMissing:
      return 'Cover Photo - Missing';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoExplicitNudity:
      return 'Cover Photo - Explicit Nudity';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoRacism:
      return 'Cover Photo - Racism';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoHateSpeech:
      return 'Cover Photo - Hate Speech';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoIllegalActivity:
      return 'Cover Photo - Illegal Activity';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoStaffImpersonation:
      return 'Cover Photo - Staff Impersonation';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoCreatorImpersonation:
      return 'Cover Photo - Creator Impersonation';

    // - Status
    case CreatorApplicantIssueType.BasicInfoStatusRacism:
      return 'Status - Racism';

    case CreatorApplicantIssueType.BasicInfoStatusHateSpeech:
      return 'Status - Hate Speech';

    case CreatorApplicantIssueType.BasicInfoStatusIllegalActivity:
      return 'Status - Illegal Activity';

    case CreatorApplicantIssueType.BasicInfoStatusStaffImpersonation:
      return 'Status - Staff Impersonation';

    case CreatorApplicantIssueType.BasicInfoStatusCreatorImpersonation:
      return 'Status - Creator Impersonation';

    case CreatorApplicantIssueType.BasicInfoStatusIncludesCode:
      return 'Status - Includes Code';

    // - About
    case CreatorApplicantIssueType.BasicInfoAboutRacism:
      return 'About - Racism';

    case CreatorApplicantIssueType.BasicInfoAboutHateSpeech:
      return 'About - Hate Speech';

    case CreatorApplicantIssueType.BasicInfoAboutIllegalActivity:
      return 'About - Illegal Activity';

    case CreatorApplicantIssueType.BasicInfoAboutStaffImpersonation:
      return 'About - Staff Impersonation';

    case CreatorApplicantIssueType.BasicInfoAboutCreatorImpersonation:
      return 'About - Creator Impersonation';

    case CreatorApplicantIssueType.BasicInfoAboutIncludesCode:
      return 'About - Includes Code';

    // Twitter Verification
    // - Username
    case CreatorApplicantIssueType.TwitterVerificationUsernameNotFound:
      return 'Twitter Profile - Not Found';

    case CreatorApplicantIssueType.TwitterVerificationUsernamePrivate:
      return 'Twitter Profile - Not Public';

    case CreatorApplicantIssueType.TwitterVerificationUsernameNotCreator:
      return 'Twitter Profile - Not Creator';

    // - Verification Tweet
    case CreatorApplicantIssueType.TwitterVerificationTweetNotFound:
      return 'Verification Tweet - Not Found';

    case CreatorApplicantIssueType.TwitterVerificationTweetMissingCode:
      return 'Verification Tweet - Missing Code';

    case CreatorApplicantIssueType.TwitterVerificationTweetIncorrectCode:
      return 'Verification Tweet - Incorrect Code';

    default:
      return 'Unknown';
  }
};

export const typeToDescription = (type: CreatorApplicantIssueType): string => {
  switch (type) {
    // Basic Info
    // - Username
    case CreatorApplicantIssueType.BasicInfoUsernameMissing:
      return 'Your username must be changed from the default. Please choose an actual username and resubmit.';

    case CreatorApplicantIssueType.BasicInfoUsernameRacism:
      return 'Your username contains racial slurs. Please choose a different username and resubmit.';

    case CreatorApplicantIssueType.BasicInfoUsernameHateSpeech:
      return 'Your username contains hate speech or targetted harassment. Please choose a different username and resubmit.';

    case CreatorApplicantIssueType.BasicInfoUsernameIllegalActivity:
      return 'Your username contains themes of illegal activity. Please choose a different username and resubmit.';

    case CreatorApplicantIssueType.BasicInfoUsernameStaffImpersonation:
      return 'Your username could be mistaken for a staff member on this platform. Please choose a different username and resubmit.';

    case CreatorApplicantIssueType.BasicInfoUsernameCreatorImpersonation:
      return 'Your username is already in use by an established creator. Please choose a different username and resubmit.';

    // - Name
    case CreatorApplicantIssueType.BasicInfoNameMissing:
      return 'Your name must be changed from the default. Please choose an actual name and resubmit.';

    case CreatorApplicantIssueType.BasicInfoNameRacism:
      return 'Your name contains racial slurs. Please choose a different name and resubmit.';

    case CreatorApplicantIssueType.BasicInfoNameHateSpeech:
      return 'Your name contains hate speech or targetted harassment. Please choose a different name and resubmit.';

    case CreatorApplicantIssueType.BasicInfoNameIllegalActivity:
      return 'Your name contains themes of illegal activity. Please choose a different name and resubmit.';

    case CreatorApplicantIssueType.BasicInfoNameStaffImpersonation:
      return 'Your name could be mistaken for a staff member on this platform. Please choose a different name and resubmit.';

    case CreatorApplicantIssueType.BasicInfoNameCreatorImpersonation:
      return 'Your name is already in use by an established creator. Please choose a different name and resubmit.';

    case CreatorApplicantIssueType.BasicInfoNameIncludesCode:
      return 'Your name includes irrelevant code such as HTML entities, JavaScript, or CSS. Please remove and resubmit.';

    // - Profile Picture
    case CreatorApplicantIssueType.BasicInfoProfilePictureMissing:
      return 'Your profile picture must be changed from the default. Please choose an actual profile picture and resubmit.';

    case CreatorApplicantIssueType.BasicInfoProfilePictureExplicitNudity:
      return 'Your profile picture contains explicit nudity. Nudity is allowed but keep it subtle. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoProfilePictureCreatorImpersonation:
      return 'Your profile picture seems to belong to an established creator. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoProfilePictureRacism:
      return 'Your profile picture contains racist themes. Please choose a differnet image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoProfilePictureHateSpeech:
      return 'Your profile picture contains hate speech or targetted harassment. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoProfilePictureIllegalActivity:
      return 'Your profile picture contains themes of illegal activity. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoProfilePictureStaffImpersonation:
      return 'Your profile picture could be mistaken for a staff member on this platform. Please choose a different image and resubmit.';

    // - Cover Photo Picture
    case CreatorApplicantIssueType.BasicInfoCoverPhotoMissing:
      return 'Your cover photo must be changed from the default. Please choose an actual cover photo and resubmit.';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoExplicitNudity:
      return 'Your cover photo contains explicit nudity. Nudity is allowed but keep it subtle. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoCreatorImpersonation:
      return 'Your cover photo seems to belong to an established creator. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoRacism:
      return 'Your cover photo contains racist themes. Please choose a differnet image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoHateSpeech:
      return 'Your cover photo contains hate speech or targetted harassment. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoIllegalActivity:
      return 'Your cover photo contains themes of illegal activity. Please choose a different image and resubmit.';

    case CreatorApplicantIssueType.BasicInfoCoverPhotoStaffImpersonation:
      return 'Your cover photo could be mistaken for a staff member on this platform. Please choose a different image and resubmit.';

    // - Status
    case CreatorApplicantIssueType.BasicInfoStatusRacism:
      return 'Your status contains racial slurs. Please correct it and resubmit.';

    case CreatorApplicantIssueType.BasicInfoStatusHateSpeech:
      return 'Your status contains hate speech or targetted harassment. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoStatusIllegalActivity:
      return 'Your status contains themes of illegal activity. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoStatusStaffImpersonation:
      return 'Your status could be mistaken for a staff member on this platform. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoStatusCreatorImpersonation:
      return 'Your status is already in use by an established creator. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoStatusIncludesCode:
      return 'Your status includes irrelevant code such as HTML entities, JavaScript, or CSS. Please remove and resubmit.';

    // - About
    case CreatorApplicantIssueType.BasicInfoAboutRacism:
      return 'Your about/bio contains racial slurs. Please correct it and resubmit.';

    case CreatorApplicantIssueType.BasicInfoAboutHateSpeech:
      return 'Your about/bio contains hate speech or targetted harassment. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoAboutIllegalActivity:
      return 'Your about/bio contains themes of illegal activity. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoAboutStaffImpersonation:
      return 'Your about/bio could be mistaken for a staff member on this platform. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoAboutCreatorImpersonation:
      return 'Your about/bio is already in use by an established creator. Please correct this and resubmit.';

    case CreatorApplicantIssueType.BasicInfoAboutIncludesCode:
      return 'Your about/bio includes irrelevant code such as HTML entities, JavaScript, or CSS. Please remove and resubmit.';

    // Twitter Verification
    // - Username
    case CreatorApplicantIssueType.TwitterVerificationUsernameNotFound:
      return 'The Twitter profile you provided was not found. Please provide an active Twitter profile and resubmit.';

    case CreatorApplicantIssueType.TwitterVerificationUsernamePrivate:
      return 'The Twitter profile you submitted is set to private. Please make the profile public and resubmit.';

    case CreatorApplicantIssueType.TwitterVerificationUsernameNotCreator:
      return 'The Twitter profile you submitted does not appear to be an adult creator. We are only allowing creators into our Creator Program.';

    // Verification Tweet
    case CreatorApplicantIssueType.TwitterVerificationTweetNotFound:
      return `We couldn't locate your verification tweet. Please resend the tweet, click 'Done' below, and resubmit.`;

    case CreatorApplicantIssueType.TwitterVerificationTweetMissingCode:
      return `Your verification tweet is missing your unique verification code. Please tweet the text exactly as provided, click 'Done' below, and resubmit.`;

    case CreatorApplicantIssueType.TwitterVerificationTweetIncorrectCode:
      return `Your verification tweet contains an incorrect verification code. Please tweet the text exactly as provided, click 'Done' below, and resubmit.`;

    default:
      return 'Unknown';
  }
};

export const typeToAdminGuideline = (
  type: CreatorApplicantIssueType
): string => {
  switch (type) {
    // Basic Info
    case CreatorApplicantIssueType.BasicInfoUsernameMissing:
    case CreatorApplicantIssueType.BasicInfoNameMissing:
    case CreatorApplicantIssueType.BasicInfoProfilePictureMissing:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoMissing:
      return 'Is it missing, or still set to the default?';

    case CreatorApplicantIssueType.BasicInfoUsernameRacism:
    case CreatorApplicantIssueType.BasicInfoNameRacism:
    case CreatorApplicantIssueType.BasicInfoProfilePictureRacism:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoRacism:
    case CreatorApplicantIssueType.BasicInfoStatusRacism:
    case CreatorApplicantIssueType.BasicInfoAboutRacism:
      return 'Does it contain racism or racial slurs?';

    case CreatorApplicantIssueType.BasicInfoUsernameHateSpeech:
    case CreatorApplicantIssueType.BasicInfoNameHateSpeech:
    case CreatorApplicantIssueType.BasicInfoProfilePictureHateSpeech:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoHateSpeech:
    case CreatorApplicantIssueType.BasicInfoStatusHateSpeech:
    case CreatorApplicantIssueType.BasicInfoAboutHateSpeech:
      return 'Does it contain hate speech or harassment towards a person or group of people?';

    case CreatorApplicantIssueType.BasicInfoUsernameIllegalActivity:
    case CreatorApplicantIssueType.BasicInfoNameIllegalActivity:
    case CreatorApplicantIssueType.BasicInfoProfilePictureIllegalActivity:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoIllegalActivity:
    case CreatorApplicantIssueType.BasicInfoStatusIllegalActivity:
    case CreatorApplicantIssueType.BasicInfoAboutIllegalActivity:
      return 'Does it contain themes of illegal activity?';

    case CreatorApplicantIssueType.BasicInfoUsernameStaffImpersonation:
    case CreatorApplicantIssueType.BasicInfoNameStaffImpersonation:
    case CreatorApplicantIssueType.BasicInfoProfilePictureStaffImpersonation:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoStaffImpersonation:
    case CreatorApplicantIssueType.BasicInfoStatusStaffImpersonation:
    case CreatorApplicantIssueType.BasicInfoAboutStaffImpersonation:
      return 'Could it be mistaken for a staff member on this platform?';

    case CreatorApplicantIssueType.BasicInfoUsernameCreatorImpersonation:
    case CreatorApplicantIssueType.BasicInfoNameCreatorImpersonation:
    case CreatorApplicantIssueType.BasicInfoProfilePictureCreatorImpersonation:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoCreatorImpersonation:
    case CreatorApplicantIssueType.BasicInfoStatusCreatorImpersonation:
    case CreatorApplicantIssueType.BasicInfoAboutCreatorImpersonation:
      return 'Is it an obvious attempt to impersonate an established creator?';

    case CreatorApplicantIssueType.BasicInfoProfilePictureExplicitNudity:
    case CreatorApplicantIssueType.BasicInfoCoverPhotoExplicitNudity:
      return 'Does it contain explicit nudity? (Subtle nudity is allowed)';

    // Twitter Verification
    case CreatorApplicantIssueType.TwitterVerificationUsernameNotFound:
      return 'Is the profile not found or suspended?';

    case CreatorApplicantIssueType.TwitterVerificationUsernamePrivate:
      return 'Is this profile set to private?';

    case CreatorApplicantIssueType.TwitterVerificationUsernameNotCreator:
      return 'Does this profile not appear to be an adult creator?';

    case CreatorApplicantIssueType.TwitterVerificationTweetNotFound:
      return 'Is their verification tweet missing entirely?';

    case CreatorApplicantIssueType.TwitterVerificationTweetMissingCode:
      return 'Is their verification tweet missing the code at the end?';

    case CreatorApplicantIssueType.TwitterVerificationTweetIncorrectCode:
      return 'Is their verification tweet code incorrect?';

    case CreatorApplicantIssueType.BasicInfoNameIncludesCode:
    case CreatorApplicantIssueType.BasicInfoStatusIncludesCode:
    case CreatorApplicantIssueType.BasicInfoAboutIncludesCode:
      return 'Does it contain HTML entities or other unnecessary code?';

    default:
      'Unknown';
  }
};
