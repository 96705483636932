import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import type { FC, MutableRefObject } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import useClickAwayListener from '../hooks/useClickAwayListener';

import s from './Drawer.module.scss';

export const TRANSITION_DURATION_MS = 225;

type Props = {
  anchorRef: MutableRefObject<HTMLElement> | MutableRefObject<undefined>;
  classNames?: {
    root?: string;
    drawer?: string;
    backdrop?: string;
  };
  isOpen: boolean;
  onClose: () => void;
};

const Drawer: FC<Props> = ({
  anchorRef,
  children,
  classNames = {
    root: '',
    drawer: '',
    backdrop: '',
  },
  isOpen = false,
  onClose,
}) => {
  const [_isOpen, _setIsOpen] = useState(isOpen);
  const [isMounted, setIsMounted] = useState(false);
  const drawerRef = useRef();
  const router = useRouter();

  useClickAwayListener([drawerRef, anchorRef], () => {
    _setIsOpen(false);
  });

  useEffect(() => {
    _setIsOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let timer;

    if (_isOpen) {
      document.documentElement.classList.add('isLocked');
      setIsMounted(true);
    } else {
      timer = setTimeout(() => {
        document.documentElement.classList.remove('isLocked');
        setIsMounted(false);

        if (onClose) {
          onClose();
        }
      }, TRANSITION_DURATION_MS);
    }

    return () => clearTimeout(timer);
  }, [_isOpen]);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      _setIsOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events]);

  const content = (
    <CSSTransition
      in={_isOpen}
      timeout={TRANSITION_DURATION_MS}
      classNames={{
        enterActive: s.enterActive,
        enterDone: s.enterDone,
        exitActive: s.exitActive,
        exitDone: s.exitDone,
      }}>
      {_isOpen || isMounted ? (
        <div role="presentation" className={cn(s.root, classNames.root)}>
          <div ref={drawerRef} className={cn(s.drawer, classNames.drawer)}>
            {children}
          </div>
          <div className={cn(s.backdrop, classNames.backdrop)} />
        </div>
      ) : (
        <></>
      )}
    </CSSTransition>
  );

  if (typeof window !== 'undefined') {
    return createPortal(content, document.body);
  } else {
    return null;
  }
};

export default Drawer;
