import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import { ColorVariant, validEnum } from '../utils';

import s from './Spinner.module.scss';

type Props = {
  classNames?: {
    root?: string;
  };
  variant?: ColorVariant;
};

const Spinner: FC<Props> = ({
  classNames = {
    root: '',
  },
  variant = ColorVariant.Primary,
}) => {
  if (!validEnum(ColorVariant, variant)) {
    variant = ColorVariant.Primary;
  }

  return (
    <div
      className={cn(
        s.root,
        variant === ColorVariant.Primary && s.variantPrimary,
        variant === ColorVariant.Secondary && s.variantSecondary,
        variant === ColorVariant.Success && s.variantSuccess,
        variant === ColorVariant.Danger && s.variantDanger,
        variant === ColorVariant.Warning && s.variantWarning,
        variant === ColorVariant.Info && s.variantInfo,
        classNames.root
      )}
    />
  );
};

export default Spinner;
