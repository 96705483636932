import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const AdminIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M9.9466875,7.10542736e-15 L19.96575,4.32 C19.9657596,6.71337154 20.0756039,8.8297622 20.0600829,10.7317428 C16.4821943,10.7317428 13.8724924,11.6873702 12.2309773,13.598625 C10.5894621,15.5098798 10.5894621,18.7133086 12.2309773,23.2089116 C11.7383035,23.4395444 10.9889361,23.7032917 9.982875,24.0001535 C6.95675,22.9781327 4.6035625,21.3587121 2.9233125,19.1418917 C0.428181441,15.8499666 -1.70530257e-13,12.7043599 -1.70530257e-13,4.32 L9.9466875,7.10542736e-15 Z M18.672,13.344 C21.6145731,13.344 24,15.7294269 24,18.672 C24,21.6145731 21.6145731,24 18.672,24 C15.7294269,24 13.344,21.6145731 13.344,18.672 C13.344,15.7294269 15.7294269,13.344 18.672,13.344 Z M18.7354286,19.1418917 C16.2816596,19.1418917 15.5232422,20.4837405 15.5232422,20.8034849 C15.5232422,21.1232294 16.7281992,22.4671222 18.6802693,22.4671222 C20.6323393,22.4671222 21.8372963,21.1180262 21.8372963,20.8034849 C21.8372963,20.4889436 21.1891975,19.1418917 18.7354286,19.1418917 Z M18.7354286,14.9931429 C17.8596628,14.9931429 17.1497143,15.7030913 17.1497143,16.5788571 C17.1497143,17.454623 17.8596628,18.1645714 18.7354286,18.1645714 C19.6111944,18.1645714 20.3211429,17.454623 20.3211429,16.5788571 C20.3211429,15.7030913 19.6111944,14.9931429 18.7354286,14.9931429 Z"
    />
  </SvgIcon>
);

export default AdminIcon;
