import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const ExploreIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M19.2,4.848 L9.408,9.36 L4.848,19.2 L14.592,14.592 L19.2,4.848 Z M12,10.656 C12.7422707,10.656 13.344,11.2577293 13.344,12 C13.344,12.7422707 12.7422707,13.344 12,13.344 C11.2577293,13.344 10.656,12.7422707 10.656,12 C10.656,11.2577293 11.2577293,10.656 12,10.656 Z"
    />
  </SvgIcon>
);

export default ExploreIcon;
