import React from 'react';
import type { FC, ReactNode } from 'react';

// Used by all icons. Not actually used by SvgIcon itself
export type IconProps = {
  className?: string;
  color?: string;
};

type Props = {
  children?: ReactNode;
};

const SvgIcon: FC<Props> = ({ children }) => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {children}
    </g>
  </svg>
);

export default SvgIcon;
