import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import type { FC } from 'react';

import { HttpMethod } from 'core/utils/http';

import NavBar from 'components/NavBar';
import {
  // BADGES_API_POLL_INTERVAL_SECS,
  GA_MEASUREMENT_ID,
  NODE_ENV,
} from 'config';
// import useBadges from 'hooks/data/useBadges';
import useApi from 'hooks/useApi';
import useUser from 'hooks/data/account/useUser';
import { sessionApiRoute } from 'routes';

import 'normalize.css/normalize.css';
import 'nprogress/nprogress.css';

import s from './App.module.scss';

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  // useAlerts({
  //  refreshInterval: BADGES_API_POLL_INTERVAL_SECS * 1000,
  // });
  const { data: userData } = useUser();
  const { request: extendSessionRequest } = useApi('extendSession');

  useEffect(() => {
    const canHover = !matchMedia('(hover: none)').matches;

    if (canHover) {
      document.documentElement.classList.add('canHover');
    }
  }, []);

  useEffect(() => {
    if (userData?.user) {
      (async () => {
        try {
          await extendSessionRequest({
            url: sessionApiRoute(),
            method: HttpMethod.Post,
          });
        } catch (err) {
          console.log(err);
          // fail silently
        }
      })();
    }
  }, [userData]);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    const handleRouteChangeStart = () => {
      NProgress.start();
    };

    const handleRouteChangeComplete = () => {
      NProgress.done();
    };

    const handleRouteChangeError = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [router.events]);

  /*
  not actually used. but probably needs something similar when we switch back to
  jsonwebtokens
  useEffect(() => {
    axios.defaults.timeout = AXIOS_TIMEOUT_SECS * 1000;

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status === HttpStatusCode.Unauthorized) {
          // user is getting a 401 because their session has a problem.
          // it may have been deleted from the database (due to expiry or manual
          // removal). we send them to /login?next=xxx
          const { pathname, search } = window.location;

          window.location.href = `${loginRoute()}?next=${encodeURIComponent(
            `${pathname}${search}`
          )}`;
        } else if (error.response?.status === HttpStatusCode.Forbidden) {
          // user gets a 403 when they try to access a resource they dont have access to
          // pop up an alert? or just do nothing and handle this in the inner component
        }

        return Promise.reject(error);
      }
    );
  }, []);
  */

  return (
    <div className={s.root}>
      {NODE_ENV === 'production' && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_MEASUREMENT_ID}');
            `}
          </Script>
        </>
      )}
      <NavBar />
      <main className={s.content}>
        <Component {...pageProps} />
      </main>
      <div className={s.footer}>
        &copy; {new Date().getUTCFullYear()} SexWorker
      </div>
    </div>
  );
};

export default App;
