import { ColorVariant } from '../utils';
import type User from './User';
import type { PgUser } from './User';
import { fill as fillUser } from './User';

export enum UserAlertType {
  CreatorApplicantRejected = 'creator-applicant-rejected',
}

export default interface UserAlert {
  dateCreated?: string;
  dismissable?: boolean;
  id?: number;
  type?: UserAlertType;
  userId?: number;
  uuid?: string;

  user?: User;
}

export interface PgUserAlert {
  date_created?: string | Date;
  dismissable?: boolean;
  id?: number;
  type?: UserAlertType;
  user_id?: number;
  uuid?: string;

  user?: PgUser;
}

export const fill = (data: PgUserAlert): UserAlert => {
  const userAlert: UserAlert = {
    dateCreated: data.date_created
      ? (data.date_created as Date).toISOString()
      : undefined,
    dismissable: data.dismissable,
    id: data.id,
    type: data.type,
    userId: data.user_id,
    uuid: data.uuid,
  };

  if (data.user) {
    userAlert.user = fillUser(data.user);
  }

  return userAlert;
};

export const typeToColorVariant = (type: UserAlertType): ColorVariant => {
  switch (type) {
    case UserAlertType.CreatorApplicantRejected:
      return ColorVariant.Danger;

    default:
      return ColorVariant.Neutral;
  }
};

export const typeToString = (type: UserAlertType): string => {
  switch (type) {
    case UserAlertType.CreatorApplicantRejected:
      return 'Your Creator application has been rejected.';

    default:
      return 'Unknown';
  }
};
